body {
    overflow-x: hidden;
}

.road-infra-header {
    font-family: SF Pro Display;
    font-size: clamp(2rem, 4.458vw, 52.58px);;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -0.05em;
    text-align: center;
    color: #B10000;
}

.road-infra-text-data-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.road-infra-header-text {
    font-family: SF Pro Display;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.25px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #D60000CF;
}


.road-infra-text-para {
    font-family: SF Pro Display;
    font-size: 19px;
    font-weight: 300;
    line-height: 25.87px;
    letter-spacing: -0.02em;
    /* text-align: left; */
}


/* urban */
.data-urban {
    align-items: center;
}


/* other infra */

.port-text-data {
    font-family: SF Pro Display;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.87px;
    letter-spacing: -0.02em;
    text-align: left;
}


.other-infira-para {
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.87px;
    letter-spacing: -0.02em;
    /* text-align: left; */
}

.image-data {
    border-radius: 15px;
}


/* consuer & retail */
.consumer-header-text {
    font-family: SF Pro Display;
    font-size: 52px;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #B10000;
}

.consumer-text-para {
    font-family: SF Pro Display;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.87px;
    letter-spacing: -0.02em;
    /* text-align: left; */
}