@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');


.home-page-wrapper-section-container {
  display: flex;
  flex-wrap: wrap;
}

.home-page-column-container {
  padding: 20px;
  box-sizing: border-box;
}

.column-30 {
  flex: 0 0 30%;
}

.column-70 {
  flex: 0 0 70%;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {

  .column-40,
  .column-60 {
    flex: 0 0 100%;
  }
}


.image-data-home-page {
  width: 215px;
  height: 215px;
}


.image-data-home {
  width: 215px !important;
  height: 215px !important;
}


.hopme-page-data-wrapper {
  padding: 20px;
  border-radius: 24px;
  background-color: #FFFFFF;
}


.name-section {
  font-family: "SF Pro Display", sans-serif;
  font-family: 500;
  font-size: 24px;
  line-height: 56px;
  color: #C33333;
}

.header-designantion-home {
  font-family: "SF Pro Display", sans-serif;
  /* Specify fallback font */
  font-weight: 600;
  font-style: italic;
  font-size: 18px;
  line-height: 19.46px;
}

.home-page-para {
  font-family: "SF Pro Display", sans-serif;
  /* Specify fallback font */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.brand-container-card {
  display: flex;
  border: 0.76px solid #0000001A;
  border-radius: 6.04px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.brand-images {
  width: 250px;
  height: 80px;
}

.about-page-data-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;
}


.about-us-header {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;
  color: #C33333;
}

.about-us-para {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #363636CC;
  font-size: 16px;
  text-align: justify;
}


.our-value-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding: 20px 20px 0px 20px;
}

.our-value-header {
  font-family: SF Pro Display, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 50.57px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #C33333;
}

.our-value-para-header {
  font-family: SF Pro Display, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24.89px;
  letter-spacing: -2%;
  text-align: center;
}

.our-value-card-text-conatainer {
  font-family: SF Pro Display, sans-serif;
  font-weight: 700;
  font-size: 19.25px;
  line-height: 26.62px;
  letter-spacing: -2%;
  text-align: center;
}

.our-value-card-para-container {
  color: #363636;
  font-family: SF Pro Display, sans-serif;
  font-size: 17.11px;
  font-weight: 400;
  line-height: 22.81px;
  letter-spacing: -0.02em;
  text-align: center;
}


.our-value-card-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
}