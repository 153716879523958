.icon-data {
    width: 24px;
    height: 24px;
}

.contact-us-detail-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
}

.contact-us-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;
}

.contact-us-header {
    font-size: 36px;
    font-weight: 600;
    line-height: 56.89px;
    letter-spacing: -0.05em;
    text-align: center;
    font-family: "Inter", sans-serif;
    color: #C33333;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 18px;
}


.pemail-text {
    font-family: SF Pro Display;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    letter-spacing: -0.04em;
}

.form-data-field {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
}

.conatct-us-form-conatiner {
    display: flex;
    padding: 33.32px;
    border: 1px solid #F6F7FF;
    border-radius: 12px;
    background-color: #F6F7FF;
    flex-direction: column;
    justify-content: center;
}


.input-name {
    padding: 19.75px;
    border-radius: 13.16px;
}

::placeholder {
    font-family: Inter;
    font-size: 13.16px;
    font-weight: 600;
    line-height: 19.75px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #363636 !important;
}

.btn-container {
    background: #0013BA;
    border-radius: 13.16px;
    color: white;
    font-family: Inter;
    font-size: 18.51px;
    font-weight: 600;
    line-height: 19.75px;
    letter-spacing: -0.02em;
    padding: 13.16px 16.45px 13.16px 1px;
}




/* Footer */

.footer-image-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 80px;
    padding: 10px;
    align-items: center;
}