body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}



.mobile-dropdown {
  display: none;
}

.desktop-links {
  display: flex;
  gap: 90px;
  /* Adjust gap as needed */
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  border: 1px solid black !important;
  padding-left: 10px !important;
  border-radius: 10px !important;
  width: auto;
  padding: 10px;
}

/* Desktop view styles */
@media only screen and (min-width: 801px) {
  .nav-item {
    position: relative;
  }

  .nav-link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .dropdown-arrow {
    margin-left: 5px;
  }

  .dropdown-menu {
    width: max-content;
    position: absolute;
    top: 240%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1;
  }

  .dropdown-menu .dropdown-menu {
    width: max-content;
    top: 0px;
    left: 107%;
  }
}

@media only screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .mobile-dropdown {
    display: block;
    padding: 10px;
  }

  .desktop-links {
    display: none;
  }
}



.mobile-dropdown {
  display: none;
}

.desktop-links {
  display: flex;
  gap: 80px;
  /* Adjust gap as needed */
}

@media only screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .mobile-dropdown {
    display: block;
  }

  .desktop-links {
    display: none;
  }
}

.nav-item {
  font-family: SF Pro Display;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  /* letter-spacing: -0.02em; */
  /* text-align: left; */
  margin: 5px 0;
}
.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropdown-arrow {
  margin-left: 10px;
}

.dropdown-menu {
  padding-left: 20px;
}







.hamburger {
  display: none;
  cursor: pointer;
}

.mobile-dropdown {
  display: none;
}

.desktop-links {
  display: flex;
  gap: 80px;
  /* Adjust gap as needed */
}

@media only screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .mobile-dropdown {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }

  .desktop-links {
    display: none;
  }
}

.nav-item {
  margin: 5px 0;
}

.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropdown-arrow {
  margin-left: 10px;
}

.dropdown-menu {
  padding-left: 20px;
}


/* Hamburger Menu Styles */
.hamburger {
  display: none;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 24px;
}

.hamburger span {
  position: absolute;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: black;
  transition: transform 0.3s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.hamburger span:nth-child(3) {
  bottom: 0;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

@media only screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }
}