.image-our-management {
    width: 160px;
    height: 160px;
}

.our-card-wrapper {
    padding: 20px 24px 20px 24px;
    background-color: white;
    border-radius: 24px;
}


.our-management-card-conatiner-data {
    display: flex;
    padding: 20px 24px 20px 24px;
    background-color: white;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
}

.management-description {
    display: flex;
    align-items: center;
}

.img-1 {
    width: 230px;
    height: 80px;
}
.image-text-conatainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.management-name {
    font-family: "SF Pro Display", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
    color: #C33333;
}

.management-designation {
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #363636;
}

.mamanemnet-description-content {
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: justified;
    color: #363636CC;
}