.electrical-container-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.electrical-header-text {
  font-family: "SF Pro Display", sans-serif;
  font-size: clamp(1.5rem, 4.458vw, 44.58px);
  font-weight: 500;
  line-height: 40.87px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #B10000;
}

.insulator-hardware-conatiner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 14px;
  margin-top: 30px;
}

.header-text {
  color: #D60000CF;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18.86px;
  font-weight: 500;
  line-height: 22.51px;
  letter-spacing: -0.05em;
  /* text-align: center; */
}

.para-electrical-header {
  font-family: "SF Pro Display", sans-serif;
  font-size: 15.43px;
  font-weight: 400;
  line-height: 18.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #363636;
}


.image-container-wrapper-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.image-text-data {
  font-family: "SF Pro Display", sans-serif;
  font-size: 15.43px;
  font-weight: 400;
  line-height: 35.32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #8A0000;
}


.wire-accssories-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
}

.image-wire-accessories-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.image-wire {
  height: 180.33px;
  gap: 0px;
  border-radius: 12px 0px 0px 0px;
  opacity: 0px;

}



/* LED Business */

.led-card-container {
  background: #FFFFFFE5;
  padding: 20px;
  border-radius: 13px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.led-card-text {
  /* background: #B10000; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #B10000;
}

.led-para-text-data {
  font-family: "SF Pro Display", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #272727;
}

.led-header {
  font-family: "SF Pro Display", sans-serif;
  font-size: 44.58px;
  font-weight: 700;
  line-height: 54.87px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #B10000;
}

.header-name-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}


/* Chemical */

.chemaical-text-wrapper-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
}


.chemical-header {
  font-family: "SF Pro Display", sans-serif;
  font-size: clamp(1.5rem, 4.458vw, 44.58px);
  font-weight: 500;
  line-height: 54.87px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #B10000;
}

.chemical-header-para {
  color: #363636;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  letter-spacing: -0.02em;
  /* text-align: left; */
}

.chemical-image {
  height: 260.14px;
}

.text-image-chemical {
  font-family: "SF Pro Display", sans-serif;
  font-size: 22.08px;
  font-weight: 400;
  line-height: 47.42px;
  letter-spacing: -0.02em;
  color: #8A0000;
}

.chemical-container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}



/* Solar */
.solar-header-text {
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #B10000;
}

.solar-image {
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.para-image-solar {
    font-family: "SF Pro Display", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
}

.para-image-solar1 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: start;
}

.solar-container-info {
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #fff;
  border-radius: 18px;
}